/* eslint-disable @typescript-eslint/no-explicit-any */
import { Loader } from '@/components/core/loader';
import { Link, useNavigate, useParams } from '@tanstack/react-router';
import { useMemo, useState } from 'react';
import {
  CalendarCheck,
  Edit,
  FileText,
  Send,
  UploadIcon,
  UserX,
} from 'lucide-react';
import {
  useBulkUploadApplicants,
  useBulkUploadResume,
  useGetApplications,
  useSendBulkInterviewMutation,
  useSendInterviewMutation,
  useSendReminderMutation,
  useUpdateApplicationMutation,
} from '@/fetchers/useApplication';
import AlertModal, { AlertModalTypes } from '../common/AlertModal';
import { useIsMutating, useQueryClient } from '@tanstack/react-query';

import { Button, buttonVariants } from '@/components/ui/button';
import {
  disqualifiedFilters,
  interviewFilters,
  interviewVerdictValues,
  pipelineStages,
  resumeFilters,
  resumeVerdictValues,
} from '../position/details/constants';
import {
  useDisqualifyCandidateMutation,
  useResetInterviewMutation,
} from '@/fetchers/useInterview';
import { useUserDetailStore } from '@/fetchers/useUserDetails';
import { useCandidates } from '@/lib/useApplication/useCandidates';
import {
  DndContext,
  MouseSensor,
  PointerSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { getShouldRestrictPositionEdit } from '@/lib/utils';
import { toast } from 'sonner';
import { TPosition } from '@/fetchers/usePosition';
import { NewApplicantDialog } from './new-applicant-dialog';
import { useExport } from '@/lib/useExport';

import { format, parseISO } from 'date-fns';
import { ImportApplicantDialog } from './ImportApplicantDialog';
import { SearchInput } from '../search-input';
import { Breadcrumb } from '../breadcrumb';
import Pipeline from './Pipeline';
import Alert from '../alert';
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '@/components/ui/tooltip';
import { getIsPositionOpen } from '@/lib/getIsPositionOpen';
import { getIsPositionDraft } from '@/lib/getIsPositionDraft';

type ApplicationsProps = {
  position: TPosition;
};

export function Applications({ position }: ApplicationsProps) {
  const isPositionOpen = getIsPositionOpen(position);
  const isPositionDraft = getIsPositionDraft(position);
  // @ts-ignore
  const { organizationId, slug } = useParams({ strict: false });
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const [closeAlertModelOpen, setCloseAlertModelOpen] = useState(false);
  const disqualifyCandidate = useDisqualifyCandidateMutation();
  const [sendInterviewModalOpen, setSendInterviewModalOpen] =
    useState<any>(false);
  const [resetAlertModelOpen, setResetAlertModelOpen] = useState<any>(false);
  const resetInterview = useResetInterviewMutation();
  const [uploading, setUploading] = useState(false);
  const queryClient = useQueryClient();
  const [singleReminderModalOpen, setSingleReminderModalOpen] =
    useState<any>(false);

  const upload = useBulkUploadApplicants({
    slug,
  });

  const uploadResumes = useBulkUploadResume();

  const remindMutation = useSendReminderMutation();
  const { selectedOrganization } = useUserDetailStore();
  const { isFetching } = useGetApplications({ slug });
  const isMutating = useIsMutating();
  const sendInterviewMutation = useSendInterviewMutation();
  const bulkSendInterviewMutation = useSendBulkInterviewMutation();
  const patchApplication = useUpdateApplicationMutation({
    slug,
  });

  const { exportToExcel } = useExport();

  const { candidates } = useCandidates({
    searchTerm,
    selectedTab: 'all',
  });

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        delay: 0,
        distance: 3,
        tolerance: 10,
      },
    }),
    useSensor(PointerSensor, {
      activationConstraint: {
        delay: 250,
        distance: 3,
        tolerance: 10,
      },
    }),

    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 250,
        distance: 3,
        tolerance: 10,
      },
    })
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const appliedCandidates = useMemo(
    () =>
      candidates?.filter((item: any) => {
        const interview = item?.item?.interviews?.[0];
        return !interview;
      }),
    [candidates]
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const interviewSentCandidates = useMemo(
    () =>
      candidates?.filter((item: any) => {
        const interview = item?.item?.interviews?.[0];
        return (
          interview &&
          !interview?.completedAt &&
          interview?.finalVerdict !== 'disqualified'
        );
      }),
    [candidates]
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const interviewCompletedCandidates = useMemo(
    () =>
      candidates?.filter((item: any) => {
        const interview = item?.item?.interviews?.[0];
        return (
          interview?.completedAt && interview?.finalVerdict !== 'disqualified'
        );
      }),
    [candidates]
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const disqualifiedCandidates = useMemo(
    () =>
      candidates?.filter((item: any) => {
        const interview = item?.item?.interviews?.[0];
        return interview?.finalVerdict === 'disqualified';
      }),
    [candidates]
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function onPipelineItemClick(item: any) {
    navigate({
      to: `/organizations/$organizationId/positions/manage/$slug/$step/$id`,
      params: {
        organizationId: String(organizationId),
        slug,
        step: 'applications',
        id: item.applicationId,
      },
      search: {
        searchTerm: searchTerm,
        selectedTab: 'all',
      },
    });
  }

  function handleDisqualifyCandidate() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const item: any = closeAlertModelOpen;
    disqualifyCandidate({
      applicationId: item?.applicationId,
    });
  }

  async function handleSingleReminder() {
    const interviewIds = singleReminderModalOpen?.item?.interviews?.map(
      // @ts-ignore
      (i) => i?.id
    );
    console.log('interviewIds', { interviewIds, singleReminderModalOpen });
    await remindMutation({
      positionSlug: slug,
      // @ts-ignore
      candidates: interviewIds,
    });
    setSingleReminderModalOpen(false);
  }

  function handleBulkSendInterview() {
    bulkSendInterviewMutation({
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      applicationIds: sendInterviewModalOpen.map(
        (item: any) => item?.applicationId
      ),
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function handleDragEnd(event: any) {
    console.log('drag end', event.over);
    console.log('event.active', event.active);
    if (event?.over?.id !== 'Interview Sent') {
      return;
    }

    if (!event?.active?.id) {
      toast.error('Invalid candidate, please try again.');
      return;
    }
    sendInterviewMutation({
      applicationId: event.active.id,
    });
  }

  async function handleResetInterview() {
    await resetInterview({
      interviewId: resetAlertModelOpen?.item?.interviews?.[0]?.id,
    });
  }

  function handleExportClicked() {
    let excelData = candidates.map((candidate: any) => ({
      'Application ID': `${candidate?.applicationId}`,

      Email: candidate?.item?.email,
      Name: candidate?.item?.name,
      Phone: candidate?.item?.phone,

      interviews: candidate?.item?.interviews?.map((interview: any) => ({
        id: `${interview?.id}`,
        finalVerdict: interview?.finalVerdict,
        // completedAt: interview?.completedAt,
        completedAt:
          interview?.completedAt &&
          format(parseISO(interview?.completedAt), 'yyyy-MM-dd HH:mm'),
        // startedAt: interview?.startedAt,
        startedAt:
          interview?.startedAt &&
          format(parseISO(interview?.startedAt), 'yyyy-MM-dd HH:mm'),
        analysis: interview?.analysisSummary,
        type: interview?.assistantType
          ?.replace?.('mock-', '')
          ?.split(' ')
          ?.map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
          ?.join(' '),
      })),
      'Resume Analysis Verdict':
        // @ts-ignore
        resumeVerdictValues?.[candidate?.item?.resumeAnalysisVerdict],
      'Resume Analysis': candidate?.item?.resumeAnalysis,
      'Candidate Details URL': `${window.location.origin}/organizations/${organizationId}/positions/manage/${slug}/applications/${candidate?.applicationId}`,
      Archived: candidate?.item?.archived ? 'Yes' : 'No',
    }));

    excelData.forEach((candidate: any, index: number) => {
      candidate.interviews.forEach((interview: any) => {
        excelData[index] = {
          ...excelData[index],
          [`${interview?.type} ID`]: `${interview?.id}`,
          [`${interview?.type} Completed At`]: interview?.completedAt,
          [`${interview?.type} Verdict`]:
            // @ts-ignore
            interviewVerdictValues?.[interview?.finalVerdict],
          [`${interview?.type} Started At`]: interview?.startedAt,
          [`${interview?.type} Analysis`]: interview?.analysis,
        };
      });
    });

    excelData = excelData.map((candidate: any) => {
      const newCandidate = { ...candidate };
      delete newCandidate.interviews;
      return newCandidate;
    });

    const fileName = `${position.title} ${selectedOrganization?.name ? 'at ' + selectedOrganization?.name : ''}`;
    exportToExcel(excelData, fileName);
  }

  const exportButton = (
    <Button
      variant={'outline'}
      onClick={handleExportClicked}
      disabled={isPositionDraft}
    >
      <UploadIcon className="size-4 mr-1" />
      Export
    </Button>
  );

  const exportButtonTooltipText = `${isPositionDraft ? 'Publish' : 'Reopen'} the position to export applicants`;

  if (isFetching) {
    return <Loader />;
  }

  return (
    <>
      <AlertModal
        open={!!closeAlertModelOpen}
        onClose={() => setCloseAlertModelOpen(false)}
        title="You are about to disqualify this candidate"
        body={`Are you sure you want to disqualify this candidate? This action cannot be undone. Once disqualified, the candidate will receive an email notification.`}
        confirmText="Disqualify Candidate"
        onProceed={handleDisqualifyCandidate}
      />

      <AlertModal
        open={!!resetAlertModelOpen}
        onClose={() => setResetAlertModelOpen(false)}
        title="You are about to reset the interview for this candidate"
        body={`Are you sure you want to reset the interview for this candidate? This action cannot be undone. Once reset, the candidate will receive an email notification.`}
        confirmText="Reset Interview"
        onProceed={handleResetInterview}
      />

      {/* <AlertModal
        open={reminderModalOpen}
        onClose={() => setReminderModalOpen(false)}
        title="You are about to send reminders to all candidates"
        body={`Are you sure you want to send reminders to all candidates? The interview email will be sent to all candidates who have not started their interviews.`}
        confirmText="Send"
        onProceed={handleReminder}
      /> */}

      <AlertModal
        open={!!singleReminderModalOpen}
        onClose={() => setSingleReminderModalOpen(false)}
        // @ts-ignore
        title={`You are about to send reminder to ${singleReminderModalOpen?.item?.name || ''}`}
        // @ts-ignore
        body={`Are you sure you want to send reminder to ${singleReminderModalOpen?.item?.email || ''}? The interview email will be sent immediately.`}
        confirmText="Send"
        onProceed={handleSingleReminder}
      />

      <AlertModal
        type={AlertModalTypes.INFO}
        open={!!sendInterviewModalOpen}
        onClose={() => setSendInterviewModalOpen(false)}
        title={`You are about to send interview to  ${(sendInterviewModalOpen || [])?.length} candidates you set filter to`}
        body={`Please confirm that you want to send interview to ${(sendInterviewModalOpen || [])?.length} candidates you set filter to. This action cannot be undone. Once sent, the candidate will receive a notification.`}
        confirmText="Send"
        onProceed={handleBulkSendInterview}
      />

      <div className="flex flex-col h-full">
        {isPositionDraft && (
          <Alert
            severity="info"
            description="This position is currently in Draft status. Once everything is ready, publish the position and start interviewing candidates with Veton AI."
            action={
              <Link
                to="/organizations/$organizationId/positions/manage/$slug/$step"
                params={{
                  organizationId,
                  slug,
                  step: 'preview',
                }}
                className={buttonVariants({ variant: 'link' })}
              >
                Publish
              </Link>
            }
            className="mb-4"
          />
        )}
        <Breadcrumb
          items={[
            {
              title: position.title ?? '',
              href: `/organizations/${organizationId}/positions/manage/${slug}/details`,
            },
            {
              title: 'Applications',
            },
          ]}
        />
        <div className="flex flex-col gap-4">
          <div className="flex items-center justify-between gap-4 md:gap-0">
            <h1 className="md:text-xl font-bold line-clamp-2">{`${position.title} Position ${selectedOrganization?.name ? 'at ' + selectedOrganization?.name : ''}`}</h1>
            <Link
              to={`/organizations/$organizationId/positions/manage/$slug/$step`}
              params={{
                organizationId: String(organizationId),
                slug: slug,
                step: selectedOrganization?.integrationName
                  ? 'communication'
                  : 'details',
              }}
              className={buttonVariants({ variant: 'outline' })}
            >
              <Edit className="size-4 mr-2" />
              Edit Position
            </Link>
          </div>

          <div className="flex flex-col gap-4 md:gap-0 md:flex-row md:justify-between ">
            <div className="flex gap-2">
              {!getShouldRestrictPositionEdit(
                selectedOrganization?.integrationName
              ) && <NewApplicantDialog position={position} />}

              {!getShouldRestrictPositionEdit(
                selectedOrganization?.integrationName
              ) && (
                <div>
                  {!uploading ? (
                    <ImportApplicantDialog
                      onSelectFile={async (files, spreadsheet) => {
                        if (spreadsheet) {
                          await upload({
                            // @ts-ignore
                            file: files,
                            slug,
                            organizationId,
                          });
                        } else {
                          await uploadResumes({
                            // @ts-ignore
                            files,
                            slug,
                            organizationId,
                          });
                        }
                      }}
                      onUploadCompleted={() => {
                        setUploading(false);
                        queryClient.invalidateQueries({
                          queryKey: ['applications', 'admin', slug],
                        });
                      }}
                      position={position}
                    />
                  ) : (
                    <Button isLoading disabled variant={'outline'}>
                      Uploading
                    </Button>
                  )}
                </div>
              )}

              <Tooltip>
                {!isPositionOpen ? (
                  <TooltipTrigger>{exportButton}</TooltipTrigger>
                ) : (
                  exportButton
                )}
                <TooltipContent>{exportButtonTooltipText}</TooltipContent>
              </Tooltip>
            </div>
            <SearchInput value={searchTerm} onChange={setSearchTerm} />
          </div>
        </div>
        <div className="overflow-x-auto p-0.5 h-full mt-4">
          <div className="grid grid-cols-4 gap-4 h-full w-[1200px] xl:w-full">
            <DndContext onDragEnd={handleDragEnd} sensors={sensors}>
              <Pipeline
                draggable={isMutating === 0}
                title="Applied"
                Icon={FileText}
                items={appliedCandidates}
                onClick={onPipelineItemClick}
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                dropdownActions={(item: any) => [
                  {
                    title: 'See Profile',
                    onClick: () => onPipelineItemClick(item),
                  },
                  {
                    title: 'Send Interview',
                    onClick: () =>
                      sendInterviewMutation({
                        applicationId: item?.applicationId,
                      }),
                  },
                  {
                    title: 'Disqualify',
                    onClick: () => setCloseAlertModelOpen(item),
                    customClasses: 'font-bold cursor-pointer text-rose-500',
                  },
                ]}
                filterActions={(filteredItems) => [
                  {
                    title: 'Send Interview',
                    disabled: !filteredItems?.length,
                    onClick: () => {
                      setSendInterviewModalOpen(filteredItems);
                    },
                  },
                ]}
                filterOptions={resumeFilters}
                stage={pipelineStages.applied}
              />
              <Pipeline
                title="Interview Sent"
                items={interviewSentCandidates}
                onClick={onPipelineItemClick}
                Icon={Send}
                dropdownActions={(item: any) => [
                  {
                    title: 'See Profile',
                    onClick: () => onPipelineItemClick(item),
                  },
                  {
                    title: 'Send Reminder',
                    onClick: () => setSingleReminderModalOpen(item),
                  },
                  {
                    title: 'Disqualify',
                    onClick: () => setCloseAlertModelOpen(item),
                    customClasses: 'font-bold cursor-pointer text-rose-500',
                  },
                ]}
                stage={pipelineStages.interviewSent}
              />
            </DndContext>

            <Pipeline
              title="Interview Completed"
              items={interviewCompletedCandidates}
              onClick={onPipelineItemClick}
              Icon={CalendarCheck}
              filterOptions={interviewFilters}
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              dropdownActions={(item: any) => [
                {
                  title: 'See Profile',
                  onClick: () => onPipelineItemClick(item),
                },
                {
                  title: 'Reset Interview',
                  onClick: () => setResetAlertModelOpen(item),
                  customClasses: 'font-bold cursor-pointer text-rose-500',
                },
                {
                  title: 'Disqualify',
                  onClick: () => setCloseAlertModelOpen(item),
                  customClasses: 'font-bold cursor-pointer text-rose-500',
                },
              ]}
              stage={pipelineStages.interviewCompleted}
            />
            <Pipeline
              title="Disqualified"
              Icon={UserX}
              items={disqualifiedCandidates}
              onClick={onPipelineItemClick}
              filterOptions={disqualifiedFilters}
              dropdownActions={(item: any) => [
                {
                  title: 'See Profile',
                  onClick: () => onPipelineItemClick(item),
                },
                {
                  title: 'Reset Interview',
                  onClick: () => setResetAlertModelOpen(item),
                  customClasses: 'font-bold cursor-pointer text-rose-500',
                },
                {
                  title: 'Archive',
                  onClick: () => {
                    patchApplication({
                      applicationId: item?.applicationId,
                      data: {
                        archived: true,
                      },
                    });
                  },
                  customClasses: 'font-bold cursor-pointer text-rose-500',
                },
              ]}
              stage={pipelineStages.disqualified}
            />
          </div>
        </div>
      </div>
    </>
  );
}

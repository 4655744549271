import { Button } from '@/components/ui/button';
import {
  PositionStatus,
  TPosition,
  useCommunicationForm,
  useUpdateCommunicationFormMutation,
  useUpdatePositionMutation,
} from '@/fetchers/usePosition';
import { useUserDetailStore } from '@/fetchers/useUserDetails';
import { useNavigate, useParams } from '@tanstack/react-router';
import { toast } from 'sonner';
import { mergeDataWithDefaults } from '../communication/form-defaults';
import { getIsPositionOpen } from '@/lib/getIsPositionOpen';
import './preview.css';

type TProps = {
  position?: TPosition;
};
export default function PreviewFormView({ position }: Readonly<TProps>) {
  // @ts-ignore
  const { slug, organizationId } = useParams({ strict: false });
  const updatePositionMutation = useUpdatePositionMutation();

  const { selectedOrganization } = useUserDetailStore();
  const navigate = useNavigate();

  const { data: commData } = useCommunicationForm({
    slug: position?.slug,
  });
  const updateCommunicationForm = useUpdateCommunicationFormMutation();

  const canPublish = Boolean(
    (position?.content?.length || 0) >= 11 && position?.slug
  );

  async function onPublishClickHandler() {
    try {
      if (!commData?.afterApplication || !commData?.afterInterview) {
        // TODO: Take this default communication data creation to server
        // Currently it is here because the default is on client side
        const mergedCommData = mergeDataWithDefaults(commData);
        await updateCommunicationForm({
          data: {
            selectedMethod: mergedCommData.selectedMethod,
            afterApplication: mergedCommData.afterApplication,
            afterInterview: mergedCommData.afterInterview,
            autoInterview: mergedCommData.autoInterview,
            interviewDelay: mergedCommData.autoInterview
              ? mergedCommData.interviewDelay
              : 'no_delay',
            interviewType: mergedCommData.interviewType,
            disqualified:
              mergedCommData.disqualifiedToggle === 'disabled'
                ? undefined
                : mergedCommData.disqualified,
          },
          organizationId,
          slug,
        });
      }
      await updatePositionMutation({
        data: {
          status: PositionStatus.Open,
        },
        organizationId: Number(organizationId),
        slug,
      });

      toast.success('Position published successfully');

      navigate({
        to: `/organizations/$organizationId/positions/list`,
        params: { organizationId },
      });
    } catch {
      toast.error('Failed to publish position');
    }
  }

  return (
    <div className="preview-container">
      <div className="mb-12">
        <h3 className="text-lg font-medium">Preview</h3>
        <p className="text-sm text-muted-foreground">
          Setup company details for your organization and position.
        </p>
      </div>
      <div className="w-full max-h-[50vh] md:max-h-[60vh] rounded overflow-hidden shadow-xl max-w-[700px] overflow-y-auto md:overflow-y-hidden mx-auto">
        <iframe
          src={`${window.location.origin}/careers/${selectedOrganization?.slug}/${slug}?preview=true`}
          className="preview-iframe border-0 transform md:scale-50  h-[85vh] md:h-[120vh] w-full origin-top-left pointer-events-none"
        />
      </div>

      {!getIsPositionOpen(position) && (
        <Button
          disabled={!canPublish}
          className="float-right"
          onClick={onPublishClickHandler}
        >
          Publish
        </Button>
      )}
    </div>
  );
}

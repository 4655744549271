import {
  PositionStatus,
  TPosition,
  useUpdatePositionMutation,
} from '@/fetchers/usePosition';
import { cn, getSubtitleFromPosition } from '@/lib/utils';
import { ItemActions } from '../management/item-actions';
import { Badge, BadgeProps } from '@/components/ui/badge';
import DOMPurify from 'dompurify';
import { useNavigate } from '@tanstack/react-router';
import { Route as PositionsRoute } from '@/routes/organizations.$organizationId.positions.list';
import { useUserDetailStore } from '@/fetchers/useUserDetails';
import AlertModal from '../common/AlertModal';
import { useState } from 'react';
import { positionStatusTextMap } from './positionStatusTextMap';
import { getIsPositionOpen } from '@/lib/getIsPositionOpen';

type PositionListItemProps = {
  position: TPosition;
};

function getBadgeVariantFromLabel(label: string): BadgeProps['variant'] {
  if (label.toLowerCase().includes('work')) {
    return 'default';
  }

  if (label.toLowerCase().includes('personal')) {
    return 'outline';
  }

  return 'secondary';
}

const renderContent = (html: string) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, 'text/html');
  return DOMPurify.sanitize(doc.body.innerText);
};

const statusStyles: Record<PositionStatus, string> = {
  [PositionStatus.Draft]: 'bg-gray-100 text-gray-700 border border-gray-300',
  [PositionStatus.Open]: 'bg-green-100 text-green-700 border border-green-300',
  [PositionStatus.Closed]: 'bg-red-100 text-red-700 border border-red-300',
};

const PositionListItem = ({ position }: PositionListItemProps) => {
  const { title, applicantCount = 0, tags, content, status, slug } = position;
  const isPositionOpen = getIsPositionOpen(position);
  const { organizationId } = PositionsRoute.useParams();
  const navigate = useNavigate();
  const { selectedOrganization } = useUserDetailStore();
  const [isClosePositionAlertModalOpen, setIsClosePositionAlertModalOpen] =
    useState(false);
  const [isPublishPositionAlertModalOpen, setIsPublishPositionAlertModalOpen] =
    useState(false);
  const updatePositionMutation = useUpdatePositionMutation();

  function handleOnEdit() {
    navigate({
      to: `/organizations/$organizationId/positions/manage/$slug/$step`,
      params: {
        organizationId: String(organizationId),
        slug,
        step: selectedOrganization?.integrationName
          ? 'communication'
          : 'details',
      },
    });
  }

  function handleOnClick() {
    navigate({
      to: `/organizations/$organizationId/positions/manage/$slug/$step`,
      params: {
        organizationId: String(organizationId),
        slug,
        step: 'applications',
      },
    });
  }

  function handleTogglePublishPosition() {
    updatePositionMutation({
      data: {
        status: PositionStatus.Closed,
      },
      organizationId: Number(organizationId),
      slug,
    });
  }

  const dropdownActions = [
    { title: 'Edit', onClick: () => handleOnEdit() },
    {
      title: isPositionOpen ? 'Close Position' : 'Open Position',
      onClick: () => {
        if (isPositionOpen) {
          setIsClosePositionAlertModalOpen(true);
        } else {
          setIsPublishPositionAlertModalOpen(true);
        }
      },
      customClasses: 'font-bold cursor-pointer',
    },
  ];

  return (
    <>
      <AlertModal
        open={isClosePositionAlertModalOpen}
        onClose={() => setIsClosePositionAlertModalOpen(false)}
        title="You are about to close this position"
        body={`Are you sure you want to close this position? You will not be able to receive new applications for this position.`}
        confirmText="Disable Position"
        onProceed={handleTogglePublishPosition}
      />

      <AlertModal
        open={isPublishPositionAlertModalOpen}
        onClose={() => setIsPublishPositionAlertModalOpen(false)}
        title="You are about to publish this position"
        body={`Once you publish this position, it will be visible to the public and you will start receiving applications for this position.`}
        confirmText="Publish"
        onProceed={handleTogglePublishPosition}
      />

      <div className="relative w-full">
        {dropdownActions.length > 0 && (
          <div className="gap-2 absolute top-2 right-3 flex items-center ">
            <Badge
              className={cn(
                'pointer-events-none ml-auto w-16 flex justify-center',
                statusStyles[status]
              )}
            >
              {positionStatusTextMap[status]}
            </Badge>
            <ItemActions dropdownActions={dropdownActions} />
          </div>
        )}

        <button
          className="flex flex-col items-start gap-2 rounded-sm border p-3 text-left text-sm transition-all hover:bg-accent w-full"
          onClick={handleOnClick}
        >
          <div className="flex w-full flex-col gap-1 pr-10">
            <div className="flex items-center">
              <div className="flex items-center gap-2  w-full">
                <div className="font-semibold">
                  {title}
                  {applicantCount > 0 && (
                    <span className="text-xs text-gray-600 font-normal">
                      {' | '}
                      {applicantCount} applicant{applicantCount > 1 ? 's' : ''}
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div className="text-xs font-medium">
              {getSubtitleFromPosition(position)}
            </div>
          </div>
          {content && (
            <div className="line-clamp-3 text-xs text-muted-foreground">
              <span>{renderContent(content)}</span>
            </div>
          )}
          {tags.length ? (
            <div className="flex items-center gap-2">
              {tags.map((tag) => (
                <Badge key={tag} variant={getBadgeVariantFromLabel(tag)}>
                  {tag}
                </Badge>
              ))}
            </div>
          ) : null}
        </button>
      </div>
    </>
  );
};

export default PositionListItem;

import { useDroppable } from '@dnd-kit/core';
import { useMemo, useState } from 'react';

import {
  interviewVerdictValues,
  PipelineFilter,
  pipelineStages,
  resumeVerdictValues,
} from '../position/details/constants';
import { cn } from '@/lib/utils';
import { ItemList } from '@/pages/dashboard/components/item-list';
import { ItemActions } from '../management/item-actions';
import FilterOptions from './FilterOptions';

type PipelineProps = {
  stage: (typeof pipelineStages)[keyof typeof pipelineStages];
  draggable?: boolean;
  title: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  items: any[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick: (item: any) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dropdownActions?: (item: any) => {
    title: string;
    onClick: () => void;
    customClasses?: string;
  }[];
  filterOptions?: PipelineFilter[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Icon: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  filterActions?: (filteredItems?: any[]) => {
    title: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onClick: (filteredItems?: any[]) => void;
    customClasses?: string;
    disabled?: boolean;
  }[];
};
const Pipeline = ({
  title,
  items,
  onClick,
  Icon,
  dropdownActions,
  filterOptions = [],
  filterActions,
  draggable = false,
  stage,
}: PipelineProps) => {
  const [selectedFilters, setSelectedFilters] = useState<PipelineFilter[]>([]);

  const { isOver, setNodeRef } = useDroppable({
    id: title,
  });

  const filteredItems = useMemo(() => {
    return items.filter((item) => {
      if (selectedFilters.length === 0) return true; // No filters selected, show all items

      const interview = item.item?.interviews?.[0];

      if (stage === pipelineStages.applied) {
        const resumeAnalysisVerdict = item?.item?.resumeAnalysisVerdict;
        return selectedFilters.some(
          (filter) =>
            filter.label ===
            //@ts-ignore
            resumeVerdictValues[resumeAnalysisVerdict || 'resume_not_provided']
        );
      }

      if (stage === pipelineStages.interviewCompleted) {
        return selectedFilters.some(
          (filter) =>
            //@ts-ignore
            filter.label === interviewVerdictValues[interview?.finalVerdict]
        );
      }

      if (stage === pipelineStages.disqualified) {
        const archived = item?.item?.archived;
        return selectedFilters.some(
          (filter) => filter.label === (archived ? 'Archived' : 'Not Archived')
        );
      }

      return true;
    });
  }, [items, selectedFilters, stage]);

  return (
    <div
      className={cn(
        'flex flex-col rounded shadow border border-gray-300 flex-shrink-0 h-full pb-1',
        draggable && 'overflow-visible',
        isOver && 'bg-gray-100'
      )}
      ref={setNodeRef}
    >
      <div className="h-[44px] flex items-center font-bold justify-between border-b border-gray-300  text-gray-700 rounded rounded-bl-none rounded-br-none sticky top-0 z-10 bg-white px-2">
        <div className="flex items-center text-sm 2xl:text-base">
          <Icon className="size-5 mr-2" />
          {title} {filteredItems.length > 0 && `(${filteredItems.length})`}
        </div>

        <div className="flex items-center justify-center gap-2">
          {filterOptions.length > 0 && (
            <FilterOptions
              onSelectOption={(selectedOption) => {
                if (selectedOption === 'all') {
                  setSelectedFilters([]);
                } else {
                  setSelectedFilters((prevFilters) => [
                    ...prevFilters,
                    { ...selectedOption, isSelected: true },
                  ]);
                }
              }}
              options={filterOptions}
              selectedOptions={selectedFilters}
            />
          )}

          {filterActions && (
            <ItemActions dropdownActions={filterActions(filteredItems)} />
          )}
        </div>
      </div>

      <ItemList
        dndEnabled={draggable}
        items={filteredItems}
        onClick={onClick}
        dropdownActions={dropdownActions}
        disableVirtualHeight
        layoutClasses="h-full p-1"
      />
    </div>
  );
};

export default Pipeline;

import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';

import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { RequiredStar } from '../common/RequiredStar';
import { zodResolver } from '@hookform/resolvers/zod';
import { Form } from '@/components/ui/form';
import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { ATSIntegration } from '@/fetchers/useOrganization';
import ATSAdminRoleAlert from './ATSAdminRoleAlert';
// import { useATSConnect } from "@/fetchers/useOrganization"
// import { useParams } from "@tanstack/react-router"

const connectionSchema = z.object({
  api_key: z.string({
    required_error: 'API Key is required.',
  }),
});

type ConnectionValues = z.infer<typeof connectionSchema>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function RecruiterFlow({ info, onSubmit }: any) {
  // @ts-ignore
  // const { organizationId } = useParams({ strict: false })
  // const atsConnect = useATSConnect()

  const form = useForm<ConnectionValues>({
    resolver: zodResolver(connectionSchema),
  });

  function onSubmitHandler(data: ConnectionValues) {
    console.log(data);
    // atsConnect({
    // authMethod: info.authMethod,
    // integrationType: info.id,
    // organizationId: Number(organizationId),
    // credentials: data
    // })
    onSubmit(data);
  }

  return (
    <div className="w-full flex flex-col items-center space-y-6">
      <img src={info?.bigImageUrl} className="h-[80px] object-center my-4" />
      <ATSAdminRoleAlert integration={ATSIntegration.RECRUITER_FLOW} />

      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmitHandler)}
          className="w-full space-y-4"
        >
          <div className="space-y-2 w-full">
            {/* <Label htmlFor="name">API Key<RequiredStar /></Label> */}
            {/* <Input id="name" placeholder="Your API Key" /> */}
            <FormField
              control={form.control}
              name="api_key"
              // disabled={!!organization?.name}
              render={({ field }) => (
                <FormItem className="">
                  <FormLabel aria-required>
                    API Key
                    <RequiredStar />
                  </FormLabel>
                  <FormDescription></FormDescription>
                  <FormControl>
                    <Input placeholder="Your API Key" {...field} />
                  </FormControl>

                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <div className="self-end">
            <Button className="w-full">Connect</Button>
          </div>
        </form>
      </Form>
    </div>
  );
}

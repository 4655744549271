import { useFieldArray, useFormContext } from 'react-hook-form';
import { cn } from '@/lib/utils';

import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';

import { Button } from '@/components/ui/button';

import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '@/components/ui/tooltip';
/* import AllInterviewersDialog from './interviewer-select-dialog';
 */ import { interviewers } from './interviewers';
import { PositionAIConfigFormValues } from '@/lib/usePositionAiConfigForm';
import { useEffect, useState } from 'react';
import { XIcon } from 'lucide-react';
import InterviewerCard from './interviewer-card';
import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group';
import { Loader } from '../../loader';
import { useSidebar } from '@/components/ui/sidebar';
import Alert from '../../alert';

export function PositionAIConfigForm() {
  const { control, watch } = useFormContext<PositionAIConfigFormValues>();
  /* const [isAllInterviewersDialogOpen, setIsAllInterviewersDialogOpen] =
    useState(false); */
  const [noQuestionsExist, setNoQuestionsExist] = useState(false);
  /*   const [interviewerShowcase, setInterviewerShowcase] = useState<
    TInterviewer[]
  >(interviewers.slice(0, 4)); */
  const { open } = useSidebar();

  const {
    fields: additionalQuestions,
    append,
    remove,
  } = useFieldArray({
    name: 'additionalQuestions',
    control,
  });

  const isInitialized = watch('isInitialized');

  useEffect(() => {
    if (
      additionalQuestions.length === 1 &&
      additionalQuestions[0].value === ''
    ) {
      setNoQuestionsExist(true);
    } else {
      setNoQuestionsExist(false);
    }
  }, [additionalQuestions]);

  /*  const otherInterviewers = interviewers.slice(4); */

  if (!isInitialized) {
    return <Loader className="static h-[50vh] bg-transparent" />;
  }

  return (
    <>
      <div className="flex flex-col gap-2">
        <FormField
          control={control}
          name="voiceModel"
          render={({ field }) => (
            <>
              <FormLabel>Select AI interviewer</FormLabel>
              <RadioGroup
                onValueChange={field.onChange}
                value={field.value}
                className={cn(
                  'grid grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-2',
                  !open && 'md:grid-cols-3'
                )}
              >
                {interviewers.map((interviewer) => (
                  <FormItem key={interviewer.voiceModel}>
                    <FormLabel className="[&:has([data-state=checked])>div]:border-primary">
                      <FormControl>
                        <RadioGroupItem
                          value={interviewer.voiceModel}
                          className="sr-only"
                        />
                      </FormControl>
                      <InterviewerCard interviewer={interviewer} />
                    </FormLabel>
                  </FormItem>
                ))}
                {/* <AllInterviewersDialog
                  open={isAllInterviewersDialogOpen}
                  onOpenChange={setIsAllInterviewersDialogOpen}
                  onSave={(selectedVoiceModel) => {
                    field.onChange(selectedVoiceModel);
                    const selectedInterviewer = otherInterviewers.find(
                      (interviewer) =>
                        interviewer.voiceModel === selectedVoiceModel
                    )!;
                    if (selectedInterviewer) {
                      setInterviewerShowcase((prev) => [
                        ...prev.slice(0, 3),
                        selectedInterviewer,
                      ]);
                    }
                  }}
                /> */}
              </RadioGroup>
            </>
          )}
        />

        {/*     <Button
          className="self-start"
          variant="outline"
          type="button"
          onClick={() => setIsAllInterviewersDialogOpen(true)}
        >
          Explore more
        </Button> */}
      </div>

      <div>
        {additionalQuestions.map((question, index) => (
          <FormField
            control={control}
            key={question.id}
            name={`additionalQuestions.${index}.value`}
            render={({ field }) => (
              <FormItem>
                <FormLabel className={cn(index !== 0 && 'sr-only')}>
                  Must ask questions
                </FormLabel>
                <FormDescription className={cn(index !== 0 && 'sr-only')}>
                  Our AI interviewers are robust enough to identify the right
                  questions. However if there are must ask questions, you can
                  add them here.
                </FormDescription>
                <FormControl>
                  <div className="flex max-w-xl relative">
                    <Input
                      value={field.value}
                      onChange={field.onChange}
                      placeholder="Do you believe you can be successful working remotely?"
                      onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                          event.preventDefault();
                          append({ value: '' });
                        }
                      }}
                    />

                    <Button
                      variant="ghost"
                      type="button"
                      className="absolute right-[1px] top-[1px] bg-gray-50/90 h-[38px]"
                      onClick={(event) => {
                        event.preventDefault();
                        // check if there is only one question
                        if (additionalQuestions.length === 1) {
                          // if only one clear its value
                          field.onChange('');
                          setNoQuestionsExist(true);
                        } else {
                          remove(index);
                        }
                      }}
                    >
                      <XIcon className="size-5" />
                    </Button>
                  </div>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        ))}

        <Button
          type="button"
          variant="outline"
          size="sm"
          className="mt-2"
          onClick={() => append({ value: '' })}
        >
          Add more questions
        </Button>
      </div>

      <FormField
        control={control}
        name="restrictQuestions"
        render={({ field }) => (
          <FormItem>
            <FormControl>
              <Tooltip delayDuration={0}>
                <TooltipTrigger
                  onClick={(event) => event.preventDefault()}
                  className="w-full"
                >
                  <Tabs
                    value={field.value}
                    className={cn(noQuestionsExist && 'opacity-50')}
                    onValueChange={field.onChange}
                  >
                    <div className="flex flex-col items-start md:flex-row md:justify-between md:items-center">
                      <FormLabel className="mb-2 md:mb-0 ">
                        Restrict Questions (Recommended for specific use cases
                        only)
                      </FormLabel>
                      <TabsList className="md:self-end">
                        <TabsTrigger
                          disabled={noQuestionsExist}
                          value="disabled"
                        >
                          Disabled
                        </TabsTrigger>
                        <TabsTrigger
                          disabled={noQuestionsExist}
                          value="enabled"
                        >
                          Enabled
                        </TabsTrigger>
                      </TabsList>
                    </div>
                    <TabsContent value="disabled" className="w-full">
                      <FormDescription className="w-2/4 text-left">
                        {/* {field.value === "enabled" && ()} */}
                        If you want the AI to ask{' '}
                        <span className="font-bold">only</span> the questions
                        you have added above, you can enable this feature.
                      </FormDescription>
                    </TabsContent>
                    <TabsContent className="w-full mt-4" value="enabled">
                      <Alert
                        severity="warning"
                        description="Interviewer will only ask the questions you have added above. No other questions or follow-ups will be asked."
                      />
                    </TabsContent>
                  </Tabs>
                </TooltipTrigger>
                {noQuestionsExist && (
                  <TooltipContent>
                    <p>Add custom questions to enable this feature</p>
                  </TooltipContent>
                )}
              </Tooltip>
            </FormControl>

            <FormMessage />
          </FormItem>
        )}
      />
    </>
  );
}

import { ATSIntegration } from '@/fetchers/useOrganization';
import Alert from '../alert';

const displayNameByIntegration: Partial<Record<ATSIntegration, string>> = {
  [ATSIntegration.BreezyATS]: 'Breezy',
  [ATSIntegration.CRELATE]: 'Crelate',
  [ATSIntegration.JAZZ_HR]: 'JazzHR',
  [ATSIntegration.Lever]: 'Lever',
  [ATSIntegration.RECRUITER_FLOW]: 'RecruiterFlow',
  [ATSIntegration.WORKABLE]: 'Workable',
  [ATSIntegration.ZOHO_RECRUIT]: 'Zoho Recruit',
  [ATSIntegration.MANATAL]: 'Manatal',
};

const ATSAdminRoleAlert = ({
  integration,
}: {
  integration: ATSIntegration;
}) => {
  const integrationDisplayName = displayNameByIntegration[integration] ?? '';
  return (
    <Alert
      severity="warning"
      title="Administrator role required"
      description={`You must be an administrator of ${integrationDisplayName} in order to link to Veton successfully.`}
    />
  );
};

export default ATSAdminRoleAlert;

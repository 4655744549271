import { Button } from '@/components/ui/button';

import { Dialog, DialogContent, DialogTrigger } from '@/components/ui/dialog';

import { useState } from 'react';

import { PlusIcon } from 'lucide-react';
import { ApplicationForm } from '../careers/single/application-form';
import { getIsPositionOpen } from '@/lib/getIsPositionOpen';
import {
  Tooltip,
  TooltipTrigger,
  TooltipContent,
} from '@/components/ui/tooltip';
import { getIsPositionDraft } from '@/lib/getIsPositionDraft';
import { TPosition, useApplicationForm } from '@/fetchers/usePosition';

type NewApplicantDialogProps = {
  position: TPosition;
};
export function NewApplicantDialog({ position }: NewApplicantDialogProps) {
  const [open, setOpen] = useState(false);
  const { data: applicationForm } = useApplicationForm({
    slug: position.slug,
  });
  const isPositionOpen = getIsPositionOpen(position);
  const isPositionDraft = getIsPositionDraft(position);

  const addApplicantButton = (
    <Button variant="outline" disabled={!isPositionOpen}>
      <PlusIcon className="size-5 mr-1" />
      Add Applicant
    </Button>
  );

  const addApplicantButtonTooltipText = `${isPositionDraft ? 'Publish' : 'Reopen'} the position to add applicants`;

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <Tooltip>
        <DialogTrigger asChild>
          {!isPositionOpen ? (
            <TooltipTrigger>{addApplicantButton}</TooltipTrigger>
          ) : (
            addApplicantButton
          )}
        </DialogTrigger>
        <TooltipContent>{addApplicantButtonTooltipText}</TooltipContent>
      </Tooltip>
      <DialogContent className="max-h-[70vh] overflow-y-auto">
        {applicationForm && (
          <ApplicationForm
            formTitle="Add Applicant"
            config={applicationForm}
            position={position}
            onSubmit={() => setOpen(false)}
          />
        )}
      </DialogContent>
    </Dialog>
  );
}

import { Button } from '@/components/ui/button';

import { PipelineFilter } from '../position/details/constants';
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { FilterIcon } from 'lucide-react';
import { cn } from '@/lib/utils';

const FilterOptions = ({
  options,
  selectedOptions,
  onSelectOption,
}: {
  options: PipelineFilter[];
  selectedOptions: PipelineFilter[];
  onSelectOption: (selectedOption: PipelineFilter | 'all') => void;
}) => {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          variant={selectedOptions.length > 0 ? 'default' : 'ghost'}
          className={cn('p-1 h-fit rounded-sm')}
        >
          <FilterIcon className={cn('size-4 flex-shrink-0')} />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56 cursor-pointer">
        <DropdownMenuLabel>Filter</DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuCheckboxItem
          checked={selectedOptions.length === 0}
          onCheckedChange={() => {
            onSelectOption('all');
          }}
        >
          All
        </DropdownMenuCheckboxItem>
        {options.map((option) => (
          <DropdownMenuCheckboxItem
            key={option.label}
            onCheckedChange={() => {
              onSelectOption(option);
            }}
            checked={selectedOptions.some(
              (selectedOption) => selectedOption.label === option.label
            )}
          >
            {option.label}
          </DropdownMenuCheckboxItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default FilterOptions;

import { Loader } from '@/components/core/loader';
import { useUserDetails, useUserDetailStore } from '@/fetchers/useUserDetails';
import creatingSvg from '@/assets/svg/creating.svg';
import syncingSvg from '@/assets/svg/undraw_sync.svg';
import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs';

import {
  createFileRoute,
  ErrorComponent,
  useNavigate,
} from '@tanstack/react-router';
import { PlusIcon, SquareArrowOutUpRight } from 'lucide-react';
import {
  PositionStatus,
  TPosition,
  usePaginatedPositionsQuery,
} from '@/fetchers/usePosition';
import { Button, buttonVariants } from '@/components/ui/button';
import { getSubtitleFromPosition } from '@/lib/utils';
import { useEffect, useMemo, useRef, useState } from 'react';
import AlertModal from '@/components/core/common/AlertModal';
import {
  isAtsAvailableForPlan,
  Plan,
  useATSDisconnect,
  useOrganizationStore,
} from '@/fetchers/useOrganization';
import { ATSConnectionDialog } from '@/components/core/ats';
import { toast } from 'sonner';
import { CONNECTIONS } from '@/components/core/position/details/constants';
import { Separator } from '@/components/ui/separator';
import { SearchInput } from '@/components/core/search-input';
import { Breadcrumb } from '@/components/core/breadcrumb';
import { getIsPositionOpen } from '@/lib/getIsPositionOpen';
import { useVirtualizedList } from '@/hooks/useVirtualizedList';
import PositionListItem from '@/components/core/position/position-list-item';

export const Route = createFileRoute(
  '/organizations/$organizationId/positions/list'
)({
  errorComponent: ({ error }) => {
    console.log('error @positions page', error);
    return <ErrorComponent error={error} />;
  },
  pendingComponent: () => {
    return <Loader />;
  },
  component: PositionsPage,
});

const statusTabs: {
  title: string;
  value: 'all' | PositionStatus;
}[] = [
  {
    title: 'All',
    value: 'all',
  },
  {
    title: 'Draft',
    value: PositionStatus.Draft,
  },
  {
    title: 'Open',
    value: PositionStatus.Open,
  },
  {
    title: 'Closed',
    value: PositionStatus.Closed,
  },
];

const getPositionCountByStatus = (
  positions: TPosition[],
  status: PositionStatus
) => {
  return positions.filter((position) => position.status === status).length;
};

function PositionsPage() {
  const { organizationId } = Route.useParams();
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState<'all' | PositionStatus>();
  const [searchTerm, setSearchTerm] = useState('');
  const { selectedOrganization } = useUserDetailStore();
  const [isDisconnectATSAlertModalOpen, setIsDisconnectATSAlertModal] =
    useState(false);
  const disconnect = useATSDisconnect();
  const { isFetching: isFetchingUserDetails } = useUserDetails();
  const { data: positions, isFetching: isFetchingPositions } =
    usePaginatedPositionsQuery({
      enabled: !isFetchingUserDetails,
      organizationId: Number(organizationId),
    });
  const positionsContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (selectedTab !== undefined) return;
    if (!positions || positions?.length === 0) return;

    if (positions?.length === 0) {
      setSelectedTab('all');
      return;
    }

    if (positions?.every((position) => !getIsPositionOpen(position))) {
      setSelectedTab('all');
      return;
    }

    setSelectedTab(PositionStatus.Open);
  }, [positions, selectedTab]);

  const filteredPositisions: TPosition[] = useMemo(() => {
    const allItems = positions.map((position) => ({
      ...position,
      subtitle: getSubtitleFromPosition(position),
    }));

    return allItems.filter((position) => {
      const matchesTitle =
        position.title.toLowerCase().split(searchTerm.toLowerCase()).length > 1;
      const matchesSubtitle =
        position.subtitle.toLowerCase().split(searchTerm.toLowerCase()).length >
        1;
      const matchesTags =
        position.tags?.join(' ').toLowerCase().split(searchTerm.toLowerCase())
          .length > 1;

      if (selectedTab === 'all') {
        return matchesTitle || matchesSubtitle || matchesTags;
      }

      return (
        selectedTab?.toString() === position.status.toString() &&
        (matchesTitle || matchesSubtitle)
      );
    });
  }, [selectedTab, positions, searchTerm]);

  const virtualizer = useVirtualizedList({
    itemCount: filteredPositisions.length,
    virtualizerOptions: {
      getScrollElement: () => positionsContainerRef.current,
    },
  });

  if (isFetchingPositions) {
    return <Loader />;
  }

  return (
    <>
      <AlertModal
        open={isDisconnectATSAlertModalOpen}
        onClose={() => setIsDisconnectATSAlertModal(false)}
        title="You are about to disconnect your ATS Connection"
        body={`Your ATS connection will be disconnected and you will not be able to receive applications from your ATS. Are you sure you want to disconnect?`}
        confirmText="Disconnect"
        onProceed={() =>
          disconnect({
            organizationId: Number(organizationId),
            integrationType: CONNECTIONS.find(
              (c) => c.name === selectedOrganization?.integrationName
            )?.id,
          })
        }
      />
      <Breadcrumb />
      <Tabs
        value={selectedTab?.toString()}
        className="flex flex-col max-w-2xl xl:max-w-4xl mx-auto w-full h-full"
        onValueChange={(val) =>
          setSelectedTab(val === 'all' ? val : Number(val))
        }
      >
        <div className="flex flex-col mb-4">
          <div className="flex items-center justify-between">
            <h1 className="md:text-xl font-bold">
              Positions{' '}
              {selectedOrganization?.name
                ? `- ${selectedOrganization?.name}`
                : ''}
            </h1>
            {!selectedOrganization?.integrationName && (
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`https://${selectedOrganization?.slug}${selectedOrganization?.slug === 'careers' ? '' : '.careers'}.veton.ai`}
                className={buttonVariants({ variant: 'outline' })}
              >
                <SquareArrowOutUpRight className="size-4 mr-2" />
                Careers page
              </a>
            )}
          </div>
          <Separator className="my-4" />
          <div className="flex flex-col md:flex-row md:justify-between gap-4">
            <div className="flex gap-4 justify-between md:justify-normal">
              {selectedOrganization?.integrationName ? (
                <Button
                  variant={'outline'}
                  onClick={() => {
                    if (selectedOrganization?.integrationName) {
                      setIsDisconnectATSAlertModal(true);
                    } else {
                      toast.error('No ATS connection found');
                    }
                  }}
                >
                  Disconnect {selectedOrganization?.integrationName || 'ATS'}
                </Button>
              ) : (
                <Button
                  variant="secondary"
                  onClick={() =>
                    navigate({
                      to: `/organizations/$organizationId/positions/create`,
                      params: { organizationId: String(organizationId) },
                    })
                  }
                >
                  <PlusIcon className="size-5 mr-1" />
                  New Position
                </Button>
              )}
              <TabsList>
                {statusTabs.map((tab) => (
                  <TabsTrigger
                    value={tab.value.toString()}
                    className="text-zinc-600 dark:text-zinc-200 gap-0.5"
                  >
                    {tab.title}
                    <span
                      className={selectedTab === tab.value ? '' : 'opacity-75'}
                    >
                      {`(${
                        tab.value === 'all'
                          ? positions.length
                          : getPositionCountByStatus(positions, tab.value)
                      })`}
                    </span>
                  </TabsTrigger>
                ))}
              </TabsList>
            </div>
            <SearchInput value={searchTerm} onChange={setSearchTerm} />
          </div>
        </div>

        {filteredPositisions.length === 0 ? (
          <EmptyView
            title={
              positions?.length === 0
                ? 'No positions found'
                : 'No positions found with the search criteria'
            }
            ctaText={
              positions?.length === 0
                ? 'Create your first position'
                : 'Create a new position'
            }
            subTitle={
              positions?.length === 0
                ? 'Create your first position or connect to your ATS'
                : 'Create a new position that matches the search criteria to get started'
            }
            onClick={() =>
              navigate({
                to: `/organizations/$organizationId/positions/create`,
                params: {
                  organizationId: String(organizationId),
                },
              })
            }
          />
        ) : (
          <div
            ref={positionsContainerRef}
            className="w-full overflow-y-auto no-scrollbar pb-20 md:pb-6"
          >
            <div
              style={{
                height: `${virtualizer.getTotalSize()}px`,
                width: '100%',
                position: 'relative',
              }}
            >
              {virtualizer.getVirtualItems().map((virtualItem) => {
                const position = filteredPositisions[virtualItem.index];
                return (
                  <div
                    key={virtualItem.key}
                    data-index={virtualItem.index}
                    ref={virtualizer.measureElement}
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      transform: `translateY(${virtualItem.start}px)`,
                      marginBottom: '8px', // Add margin here,
                    }}
                  >
                    <PositionListItem position={position} />
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </Tabs>
    </>
  );
}

function EmptyView({
  onClick,
  title,
  ctaText,
  subTitle,
}: {
  ctaText: string;
  subTitle: string;
  title: string;
  onClick: () => void;
}) {
  // const { connect, disconnect, isLoading } = useMergeConnect()
  const { selectedOrganization } = useUserDetailStore();
  const { selectedParentOrganization } = useOrganizationStore();

  const parentPlan = selectedParentOrganization?.plan || Plan.FREE;

  if (selectedOrganization?.integrationName) {
    return (
      <div className="w-full flex flex-col items-center justify-center my-12 space-y-8  max-w-2xl mx-auto">
        <h1 className="text-3xl font-bold max-w-sm text-center">
          Syncing in progress
        </h1>
        <img
          src={syncingSvg}
          alt="Syncing in progress"
          style={{
            width: '40%',
            height: 'auto',
          }}
        />

        {/* <p className='max-w-md text-center'>
          Want to disconnect or connect to a different ATS? Click the button below.
        </p>

        <ATSConnectionDialog /> */}
      </div>
    );
  }

  return (
    <div className="w-full flex flex-col items-center justify-center my-12 space-y-8  max-w-2xl mx-auto">
      <h1 className="text-3xl font-bold max-w-sm text-center">{title}</h1>
      <img
        src={creatingSvg}
        alt="Create a new position svg"
        style={{
          width: '40%',
          height: 'auto',
        }}
      />

      <Button onClick={onClick}>
        <PlusIcon className="h-4 w-4 mr-2" />
        {ctaText}
      </Button>

      <p className="max-w-md text-center">
        {!isAtsAvailableForPlan(parentPlan)
          ? 'Create your first board to get started'
          : subTitle}
      </p>

      {isAtsAvailableForPlan(parentPlan) && (
        <>
          <ATSConnectionDialog variant={'outline'} />
        </>
      )}
    </div>
  );
}

import { TooltipProvider } from '@/components/ui/tooltip';
import { BillingAlert } from '../billing/BillingAlert';
import { SidebarProvider } from '@/components/ui/sidebar';
import { Sidebar } from './sidebar';
import { CustomSidebarTrigger } from './sidebar-trigger';
import LayoutContainer from './layout-container';
import { AccountSwitcher } from './account-switcher';
import {
  useGetMemberships,
  useOrganizationStore,
} from '@/fetchers/useOrganization';
import { useUserDetails } from '@/fetchers/useUserDetails';
import { UpdateParentOrganizationPopup } from '../parent-organization/update-parent-organization-popup';

interface TProps {
  children: React.ReactNode;
}

export function Layout({ children }: TProps) {
  const { selectedParentOrganization } = useOrganizationStore();
  const { data: userDetails, isFetching: isFetchingUserDetails } =
    useUserDetails();
  const { data: memberships } = useGetMemberships({
    enabled: !isFetchingUserDetails && !!userDetails?.id,
  });

  const accountSwitcher = (
    <>
      <AccountSwitcher organizations={memberships || []} />
      {selectedParentOrganization?.memberRole === 'admin' && (
        <UpdateParentOrganizationPopup />
      )}
    </>
  );

  return (
    <TooltipProvider delayDuration={0}>
      <SidebarProvider>
        <Sidebar />
        <main className="flex flex-1 flex-col relative">
          <div className="flex md:hidden items-center justify-between">
            <CustomSidebarTrigger />
            <div className="flex items-center gap-2">{accountSwitcher}</div>
          </div>
          <BillingAlert layoutClasses="p-4 relative z-10" />
          <div
            className={
              'hidden md:flex items-center gap-2 absolute top-4 right-4'
            }
          >
            {accountSwitcher}
          </div>
          <LayoutContainer>{children}</LayoutContainer>
        </main>
      </SidebarProvider>
    </TooltipProvider>
  );
}

import { cn } from '@/lib/utils';
import * as ShadcnAlert from '../ui/alert';
import {
  CheckCircle,
  CircleAlert,
  Info,
  LucideIcon,
  TriangleAlert,
} from 'lucide-react';

type AlertSeverity = 'error' | 'info' | 'success' | 'warning';

type AlertProps = {
  action?: React.ReactNode;
  title?: string;
  description: React.ReactNode;
  severity: AlertSeverity;
  icon?: React.ReactNode;
  className?: string;
};

const stylesBySeverity: Record<AlertSeverity, string> = {
  success: 'bg-green-100 text-green-900',
  info: 'bg-sky-100 text-sky-900',
  warning: 'bg-orange-100 text-orange-900',
  error: 'bg-red-100 text-red-900',
};

const iconBySeverity: Record<AlertSeverity, LucideIcon> = {
  success: CheckCircle,
  info: Info,
  warning: TriangleAlert,
  error: CircleAlert,
};

const Alert = ({
  title,
  description,
  action,
  severity,
  icon,
  className,
}: AlertProps) => {
  const Icon = iconBySeverity[severity];
  return (
    <ShadcnAlert.Alert
      className={cn(
        stylesBySeverity[severity],
        'relative flex border-none rounded-md [&>svg+div]:translate-y-[0px] [&>svg]:static [&>svg~*]:pl-3',
        className
      )}
    >
      {icon ?? (
        <Icon
          className={cn('size-4 self-center', title && 'self-start')}
          style={{
            color: 'currentcolor',
          }}
        />
      )}
      <div className="flex flex-col items-start">
        {title && <ShadcnAlert.AlertTitle>{title}</ShadcnAlert.AlertTitle>}
        <ShadcnAlert.AlertDescription>
          {description}
        </ShadcnAlert.AlertDescription>
      </div>
      {action && (
        <div className="absolute top-1/2 -translate-y-1/2 right-4">
          {action}
        </div>
      )}
    </ShadcnAlert.Alert>
  );
};

export default Alert;

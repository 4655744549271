import { Separator } from '@/components/ui/separator';
import InnerSidebar from '@/components/core/inner-sidebar';
import { TPosition } from '@/fetchers/usePosition';
import { useMemo } from 'react';
import { useUserDetailStore } from '@/fetchers/useUserDetails';
import { buttonVariants } from '@/components/ui/button';
import { cn } from '@/lib/utils';
import { Link, useRouterState } from '@tanstack/react-router';

const sidebarNavItems = (orgId: string, slug: string = 'create') => [
  {
    title: 'Details',
    href: `/organizations/${orgId}/positions/manage/${slug}/details`,
  },
  {
    title: 'Description',
    href: `/organizations/${orgId}/positions/manage/${slug}/description`,
  },
  {
    title: 'Application',
    href: `/organizations/${orgId}/positions/manage/${slug}/application`,
  },
  {
    title: 'Communication',
    href: `/organizations/${orgId}/positions/manage/${slug}/communication`,
  },
  {
    title: 'AI Configuration',
    href: `/organizations/${orgId}/positions/manage/${slug}/ai-config`,
  },
  {
    title: 'Hiring Team',
    href: `/organizations/${orgId}/positions/manage/${slug}/hiring-team`,
  },
  {
    title: 'Preview',
    href: `/organizations/${orgId}/positions/manage/${slug}/preview`,
  },
];

interface PositionCreationLayoutProps {
  children: React.ReactNode;
  slug?: string;
  organizationId: number;
  title: string;
  subtitle: string;
  CustomActions?: React.ReactNode[];
  position?: TPosition;
}

export default function PositionCreationLayout({
  children,
  organizationId,
  slug,
  title,
  subtitle,
  CustomActions,
  position,
}: Readonly<PositionCreationLayoutProps>) {
  const {
    location: { pathname },
  } = useRouterState();
  const { selectedOrganization } = useUserDetailStore();

  const navItems = useMemo(() => {
    const integrationName = selectedOrganization?.integrationName;
    return sidebarNavItems(String(organizationId) || '', slug).filter(
      (item) => {
        if (!integrationName) return true;

        return (
          item?.title !== 'Details' &&
          item?.title !== 'Description' &&
          item?.title !== 'Application' &&
          item?.title !== 'Preview'
        );
      }
    );
  }, [organizationId, selectedOrganization, slug]);

  return (
    <div className="space-y-6 max-w-4xl mx-auto">
      <div className="space-y-0.5">
        <div className="flex justify-between items-center ">
          <h2 className="text-2xl font-bold tracking-tight">{title}</h2>

          <div className="flex items-end space-x-2">
            {CustomActions && CustomActions}
          </div>
        </div>
        <p className="text-muted-foreground">{subtitle}</p>
      </div>
      <Separator className="my-6" />
      <div className="flex flex-col gap-4 h-screen w-full">
        <div className="flex gap-4 overflow-x-auto h-[50px]">
          {navItems.map((item) => {
            let isDisabled = false;
            if (item.href.includes('create')) {
              isDisabled = !item.href.includes('details');
            } else if (!position?.content || position?.content?.length <= 11) {
              isDisabled = !(
                item.href.includes('details') ||
                item.href.includes('description')
              );
            }

            return (
              <Link
                key={item.title}
                // @ts-ignore
                to={item.href}
                className={buttonVariants({
                  variant: 'ghost',
                  className: cn(
                    isDisabled &&
                      'text-muted-foreground  hover:bg-transparent hover:text-muted-foreground pointer-events-none',
                    pathname === item.href && 'bg-muted',
                    !isDisabled && 'hover:bg-muted'
                  ),
                })}
              >
                {item.title}
              </Link>
            );
          })}
        </div>
        <div className="max-h-screen overflow-y-auto h-full">
          <div className="space-y-6 md:px-2 pb-[350px] md:pb-[275px] has-[.preview-container]:pb-0">
            {children}
          </div>
        </div>
      </div>
    </div>
  );
}

export function AdminSidebarLayout({
  title,
  subtitle,
  children,
  navLinks,
}: {
  title: string;
  subtitle: string;
  children: React.ReactNode;
  navLinks: { title: string; href: string }[];
}) {
  return (
    <div className="space-y-6 p-5 md:p-10 w-screen md:w-full lg:pb-16 h-screen overflow-auto lg:overflow-hidden">
      <div className="space-y-0.5">
        <div className="flex justify-between items-center max-w-7xl">
          <h2 className="text-2xl font-bold tracking-tight">{title}</h2>
        </div>
        <p className="text-muted-foreground">{subtitle}</p>
      </div>
      <Separator className="my-6" />
      <div className="flex flex-col space-y-8 lg:flex-row lg:space-x-12 lg:space-y-0">
        <aside className="-mx-4 lg:w-1/5 xl:2/5">
          <InnerSidebar items={navLinks} />
        </aside>
        <div className="flex-1 overflow-auto h-[85vh] p-2 ">{children}</div>
      </div>
    </div>
  );
}

import { useVirtualizer, VirtualizerOptions } from '@tanstack/react-virtual';
import { useState } from 'react';

export const useVirtualizedList = (options: {
  disableVirtualHeight?: boolean;
  itemCount: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  virtualizerOptions: Partial<VirtualizerOptions<any, Element>>;
}) => {
  const { itemCount, virtualizerOptions } = options;
  const [itemSizes, setItemSizes] = useState<number[]>([]);

  const virtualizer = useVirtualizer({
    count: itemCount,
    estimateSize: (index) => (itemSizes[index] || 100) + 8, // Add 8px for margin
    overscan: 5,
    measureElement: (element) => {
      const height = element.getBoundingClientRect().height;
      setItemSizes((prev) => {
        const newSizes = [...prev];
        const index =
          virtualizer.getVirtualItems()[0].index +
          parseInt((element as HTMLElement).dataset.index || '0');
        newSizes[index] = height;
        return newSizes;
      });
      return height + 8; // Add 16px for margin
    },
    ...(virtualizerOptions as Omit<
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      VirtualizerOptions<any, Element>,
      'count' | 'estimateSize'
    >),
  });

  return virtualizer;
};

import { cn } from '@/lib/utils';
import { Ellipsis } from 'lucide-react';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { Button } from '@/components/ui/button';

export function ItemActions({
  dropdownActions,
}: {
  dropdownActions: {
    title: string;
    onClick: () => void;
    disabled?: boolean;
    customClasses?: string;
  }[];
}) {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="ghost" className="p-0 h-fit">
          <Ellipsis />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-36">
        <DropdownMenuGroup>
          {dropdownActions.map((action, idx) => (
            <DropdownMenuItem
              key={idx}
              onClick={action.onClick}
              role="button"
              disabled={action.disabled}
              className={cn('cursor-pointer', action.customClasses)}
            >
              {action.title}
            </DropdownMenuItem>
          ))}
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
